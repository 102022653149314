<template>
  <div class="ev2-box stats">
    <div class="header">
      <div class="hcol1">
        <div class="title">Estatísticas de Solicitações</div>
        <div class="subtitle">Resumo de informações sobre o evento</div>
      </div>
      <div class="hcol2">
        <i class="fal fa-refresh cursor-pointer" @click="load" v-if="!loading" />
        <sl-loading v-else class="size-18" content="" />
      </div>
    </div>
    <div class="body m-t-lg">
      <section class="cont-itensSolicitacoes flexC-base">
        <div class="info-count">
          <p><span>Total de <small>domínios:</small></span></p>
          <strong>{{ stats.total }}</strong>
        </div>

        <div class="cont-graph">
          <h6>Últimos meses</h6>
          <div class="m-t" style="min-height: 180px">
            Nenhum dado para estatística
          </div>
        </div>

        <div class="grid-infos">
          <div class="g-item g-1">
            <div class="c-left">
              <img src="../../assets/icon-aprovados.png" width="27">
            </div>

            <div class="c-right flexC-base">
              <span>Aprovados</span>
              <strong>{{ stats.ativos }}<small class="hide">76%</small></strong>
            </div>
          </div><!-- END Grid 1 -->

          <div class="g-item g-2">
            <div class="c-left">
              <img src="../../assets/icon-reprovados.png" width="20">
            </div>

            <div class="c-right flexC-base">
              <span>Reprovados</span>
              <strong>{{ stats.bloqueados }}<small class="hide">19%</small></strong>
            </div>
          </div><!-- END Grid 2 -->
        </div>

        <div class="grid-infos">
          <div class="g-item g-1">
            <div class="c-left">
              <img src="../../assets/icon-processo.png" width="20">
            </div>

            <div class="c-right flexC-base">
              <span>Em processo</span>
              <strong>{{Number(stats.pendente) + Number(stats.analise) + Number(stats.documentacao) + Number(stats.pagamento)}}<small class="hide">5%</small></strong>
            </div>
          </div><!-- END Grid 1 -->

          <div class="g-item g-2">
            <div class="c-left">
              <img src="../../assets/icon-analise.png" width="16">
            </div>

            <div class="c-right flexC-base">
              <span>Para Análise</span>
              <strong>{{ stats.analise }}<small class="hide">1%</small></strong>
            </div>
          </div><!-- END Grid 2 -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SlLoading from "components/layout/components/Loading.vue";
import {stats} from "@/domain/fenaju/services";

export default {
  name: "Stats",
  components: {SlLoading},
  data () {
    return {
      loading: true,
      stats: {
        total: null,
        pendente: null,
        analise: null,
        documentacao: null,
        pagamento: null,
        registro: null,
        ativos: null,
        bloqueados: null,
        expirados: null
      }
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      stats()
          .then(response => {
            this.stats = Object.assign(this.stats, response.data)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>
