<template>
  <div class="ev2-box req-atencao">
    <div class="header">
      <div class="hcol1">
        <div class="title">Requer atenção</div>
        <div class="subtitle">Situações que precisam de atenção imediata</div>
      </div>
    </div>
    <div class="body m-t-lg" style="margin-right: -10px">
      Nenhum dado para estatística
<!--      <section class="cont-itensAtencao flexC-base">
        <a class="item-unico">
          <div class="c-left">
            <span>ph</span>
          </div>

          <div class="c-right flexC-base">
            <strong>Pedro Henrique</strong>
            <span>pedroleiloeiro.leilao.br</span>
            <p>Efetuou o pedido de registro há 10 dias e ainda não foi analisado.</p>
          </div>
        </a>&lt;!&ndash; END Único &ndash;&gt;

        <a class="item-unico">
          <div class="c-left">
            <span>gr</span>
          </div>

          <div class="c-right flexC-base">
            <strong>Gabriel Reis</strong>
            <span>gabriel.leilao.br</span>
            <p>Enviou documentação pendente há 5 dias e não foi analisada.</p>
          </div>
        </a>&lt;!&ndash; END Único &ndash;&gt;

        <a class="item-unico">
          <div class="c-left">
            <span>mn</span>
          </div>

          <div class="c-right flexC-base">
            <strong>Margarida Neres</strong>
            <span>margaridaneres.leilao.br</span>
            <p>Registro autorizado ao registro.br e não foi finalizado há 3 dias</p>
          </div>
        </a>&lt;!&ndash; END Único &ndash;&gt;
        <a class="item-unico">
          <div class="c-left">
            <span>mn</span>
          </div>

          <div class="c-right flexC-base">
            <strong>Margarida Neres</strong>
            <span>margaridaneres.leilao.br</span>
            <p>Registro autorizado ao registro.br e não foi finalizado há 3 dias</p>
          </div>
        </a>&lt;!&ndash; END Único &ndash;&gt;
        <a class="item-unico">
          <div class="c-left">
            <span>mn</span>
          </div>

          <div class="c-right flexC-base">
            <strong>Margarida Neres</strong>
            <span>margaridaneres.leilao.br</span>
            <p>Registro autorizado ao registro.br e não foi finalizado há 3 dias</p>
          </div>
        </a>&lt;!&ndash; END Único &ndash;&gt;
      </section>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "RequerAtencao"
}
</script>
