var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ev2-box stats"},[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"hcol2"},[(!_vm.loading)?_c('i',{staticClass:"fal fa-refresh cursor-pointer",on:{"click":_vm.load}}):_c('sl-loading',{staticClass:"size-18",attrs:{"content":""}})],1)]),_c('div',{staticClass:"body m-t-lg"},[_c('section',{staticClass:"cont-itensSolicitacoes flexC-base"},[_c('div',{staticClass:"info-count"},[_vm._m(1),_c('strong',[_vm._v(_vm._s(_vm.stats.total))])]),_vm._m(2),_c('div',{staticClass:"grid-infos"},[_c('div',{staticClass:"g-item g-1"},[_vm._m(3),_c('div',{staticClass:"c-right flexC-base"},[_c('span',[_vm._v("Aprovados")]),_c('strong',[_vm._v(_vm._s(_vm.stats.ativos)),_c('small',{staticClass:"hide"},[_vm._v("76%")])])])]),_c('div',{staticClass:"g-item g-2"},[_vm._m(4),_c('div',{staticClass:"c-right flexC-base"},[_c('span',[_vm._v("Reprovados")]),_c('strong',[_vm._v(_vm._s(_vm.stats.bloqueados)),_c('small',{staticClass:"hide"},[_vm._v("19%")])])])])]),_c('div',{staticClass:"grid-infos"},[_c('div',{staticClass:"g-item g-1"},[_vm._m(5),_c('div',{staticClass:"c-right flexC-base"},[_c('span',[_vm._v("Em processo")]),_c('strong',[_vm._v(_vm._s(Number(_vm.stats.pendente) + Number(_vm.stats.analise) + Number(_vm.stats.documentacao) + Number(_vm.stats.pagamento))),_c('small',{staticClass:"hide"},[_vm._v("5%")])])])]),_c('div',{staticClass:"g-item g-2"},[_vm._m(6),_c('div',{staticClass:"c-right flexC-base"},[_c('span',[_vm._v("Para Análise")]),_c('strong',[_vm._v(_vm._s(_vm.stats.analise)),_c('small',{staticClass:"hide"},[_vm._v("1%")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hcol1"},[_c('div',{staticClass:"title"},[_vm._v("Estatísticas de Solicitações")]),_c('div',{staticClass:"subtitle"},[_vm._v("Resumo de informações sobre o evento")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Total de "),_c('small',[_vm._v("domínios:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont-graph"},[_c('h6',[_vm._v("Últimos meses")]),_c('div',{staticClass:"m-t",staticStyle:{"min-height":"180px"}},[_vm._v(" Nenhum dado para estatística ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-left"},[_c('img',{attrs:{"src":require("../../assets/icon-aprovados.png"),"width":"27"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-left"},[_c('img',{attrs:{"src":require("../../assets/icon-reprovados.png"),"width":"20"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-left"},[_c('img',{attrs:{"src":require("../../assets/icon-processo.png"),"width":"20"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-left"},[_c('img',{attrs:{"src":require("../../assets/icon-analise.png"),"width":"16"}})])
}]

export { render, staticRenderFns }